import React from 'react'
import {
  BrowserRouter as Router, Route, Switch, Redirect,
} from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'shards-ui/dist/css/shards.min.css'
import './App.css'
import RedirectToOoca from './pages/RedirectToOoca'
import PrivilegeApplyForm from './pages/PrivilegeApplyForm'
import PrivilegeApplyFormSlug from './pages/PrivilegeApplyFormSlug'
import Navbar from './components/Navbar'
import PrivacyPolicy from './pages/PrivacyPolicy'
import TermsAndCondition from './pages/TermsAndCondition'
import Finale from './pages/Finale'


// const App = () => (
//   <Router>
//     <Navbar />
//     <Switch>
//       <Route exact path="/" component={PrivilegeApplyForm} />
//       <Route path="/finale" component={Finale} />
//       <Route path="/get-privilege" component={RedirectToOoca} />
//       <Route path="/privacy-policy" component={PrivacyPolicy} />
//       <Route path="/term-and-condition" component={TermsAndCondition} />
//       <Route component={PrivilegeApplyFormSlug} />
//       <Redirect to="/" />
//     </Switch>
//   </Router>
// )

const App = () => (
  <h3 style={{ margin: 24 }}>This service is no longer available. Please contact ooca Customer Service for help at support@ooca.co</h3>
)

export default App
